const ReportType = {
  seasonReport: { value: 'seasonReport', name: 'Sezonowy' },
  monthlyReport: { value: 'monthlyReport', name: 'Okresowy' },
  dayByDayAccountingReport: { value: 'dayByDayAccountingReport', name: 'Księgowy dobowy' },
  rentalByRentalAccountingReport: { value: 'rentalByRentalAccountingReport', name: 'Księgowy szczegółowy' },
  monthlyAccountingReport: { value: 'monthlyAccountingReport', name: 'Księgowy miesięczny' },
  customReportFromTemplate: { value: 'customReportFromTemplate', name: 'Własny' },
  userFundsAvailableToWithdraw: { value: 'userFundsAvailableToWithdraw', name: 'Depozyty userów dostępne do wypłacenia' },
  specialDepositWithdrawalReport: { value: 'specialDepositWithdrawalReport', name: 'Wypłaty depozytów'},
  userBikeLeftInZoneBonusReport: { value: 'userBikeLeftInZoneBonusReport', name: 'Bonusy użytkowników uzyskane za zwrócenie roweru'},
  complaintsReport: { value: 'complaintsReport', name: 'Reklamacje'},
  ridersReport: { value: 'ridersReport', name: 'Raport przejazdów'},
  debtCollectionReport: { value: 'debtCollectionReport', name: 'Raport windykacyjny'},
  totalDistanceByUserReport: { value: 'totalDistanceByUserReport', name: 'Lista przejechanego dystansu przez użytkowników'},
};

if (window.location.hostname.startsWith('amazon.')) {
  ReportType.specialReport1 = { value: 'specialReport1', name: 'Dedykowany' };
}

if (window.location.hostname.startsWith('dev.') || window.location.hostname.startsWith('roovee.')) {
  ReportType.specialGlobalReport = { value: 'specialGlobalReport', name: 'Globalny (roczny)' };
  ReportType.specialMonthlyAccountingReport = { value: 'specialMonthlyAccountingReport', name: 'Raport zbiorczy' };
  ReportType.specialGlobalReportUserDebit = { value: 'specialGlobalReportUserDebit', name: 'Debety userów' };
  ReportType.globalUserAvailableFundsDepositReport = { value: 'globalUserAvailableFundsDepositReport', name: 'Depozyty dostępne do wypłacenia'};

}

export default ReportType;
