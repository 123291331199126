import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, FieldArray, reduxForm, formValueSelector } from 'redux-form';
import { Paper, Typography, Button } from '@material-ui/core';
import FormTextField from '../form/redux-form/FormTextField';
import FormCheckBox from '../form/redux-form/FormCheckBox';
import FormDraggableField from '../form/redux-form/FormDraggableField';
import PriceList from './PriceList';
import { pricingFormValidation } from '../../models/configuration/PricingValidation';
import './PricingDetailsFormStyles.scss';
import FormSelect from '../form/redux-form/FormSelect';

let PricingDetailsForm = ({
  minimalBalanceEnabled,
  initialBalanceEnabled,
  bikeLeftInZoneEnabled,
  bikeLeftOutOfPreferredZoneEnabled,
  bikeLeftOutOfOperatorZoneEnabled,
  handleSubmit,
  change,
  submitting,
  children,
  fieldPrefix,
  readonly,
  cards,
  bikeTypes,
  initialValues,
  isPlan,
  isCard,
}) => {
  return (
    <form onSubmit={handleSubmit}>
      {children}
      {!isPlan && !isCard && <Paper className="config-section" elevation={3}>
        <div>
          {bikeTypes.length > 0
            ? <Field
              name={'bikeTypeId'}
              component={FormSelect}
              readonly={initialValues.id != null}
              label="Powiązanie z typem roweru"
              style={{ width: 250 }}
              values={{ ...bikeTypes.map(bt => ({ value: bt.id, name: bt.name })) }}
            />
            : null}
        </div>
      </Paper>}

      <Typography variant="subtitle1" gutterBottom className="config-section-header">Cennik</Typography>
      <Paper className="config-section" elevation={3}>
        <FieldArray name={`${fieldPrefix}priceList`} readonly={readonly} component={PriceList} change={change} />
      </Paper>

      <Typography variant="subtitle1" gutterBottom className="config-section-header">Darmowe przejazdy</Typography>
      <Paper className="config-section" elevation={3}>
        <div>
          <Field
            name={`${fieldPrefix}freeMinutesPerDay`}
            type="number"
            component={FormTextField}
            readonly={readonly}
            label="Darmowy czas"
          />
          <Typography display="block" variant="caption">
            Liczba darmowych minut na dobę
          </Typography>
        </div>
      </Paper>

      <Typography variant="subtitle1" gutterBottom className="config-section-header">Rozpoczęcie jazdy</Typography>
      {!isCard && <Paper className="config-section" elevation={3}>
        <div>
          <Field
            name={`${fieldPrefix}rentalPreconditions.minimalBalance.enabled`}
            component={FormCheckBox}
            readonly={readonly}
            label="Wymagany minimalny stan środków"
            onChange={() => change(`${fieldPrefix}rentalPreconditions.minimalBalance.amount`, '')}
            parse={Boolean}
          />
          <Typography display="block" variant="caption">
            Wybierz, jeśli do rozpoczęcia przejazdu wymagany jest stan środków
          </Typography>
        </div>
        <div>
          <Field
            name={`${fieldPrefix}rentalPreconditions.minimalBalance.amount`}
            type="number"
            component={FormTextField}
            readonly={readonly}
            label="Kwota"
            disabled={!minimalBalanceEnabled}
            endAdornmentText="zł"
          />
        </div>
        <div>
          <Field
            name={`${fieldPrefix}rentalPreconditions.initialBalance.enabled`}
            component={FormCheckBox}
            readonly={readonly}
            label="Wymagany depozyt inicjalny"
            onChange={() => change(`${fieldPrefix}rentalPreconditions.initialBalance.amount`, '')}
            parse={Boolean}
          />
          <Typography display="block" variant="caption">
            Wybierz, jeśli do rozpoczęcia przejazdu wymagany jest depozyt inicjalny
          </Typography>
        </div>
        <div>
          <Field
            name={`${fieldPrefix}rentalPreconditions.initialBalance.amount`}
            type="number"
            component={FormTextField}
            readonly={readonly}
            label="Kwota"
            disabled={!initialBalanceEnabled}
            endAdornmentText="zł"
          />
        </div>
      </Paper>}

      <Typography variant="subtitle1" gutterBottom className="config-section-header">Bonusy</Typography>
      <Paper className="config-section" elevation={3}>
        <div>
          <Field
            name={`${fieldPrefix}deductions.bikeLeftInZone.enabled`}
            component={FormCheckBox}
            readonly={readonly}
            label="Bonus za pozostawienie w strefie preferowanej"
            onChange={() => change(`${fieldPrefix}deductions.bikeLeftInZone.amount`, '')}
            parse={Boolean}
          />
          <Typography display="block" variant="caption">
            Bonus zostanie naliczony gdy rower zostanie odebrany spoza preferowanej strefy i zwrócony w preferowanej strefie zwrotu
          </Typography>
        </div>
        <div>
          <Field
            name={`${fieldPrefix}deductions.bikeLeftInZone.amount`}
            type="number"
            component={FormTextField}
            readonly={readonly}
            label="Kwota bonusu"
            disabled={!bikeLeftInZoneEnabled}
            parse={Number}
            endAdornmentText="zł"
          />
        </div>
      </Paper>

      <Typography variant="subtitle1" gutterBottom className="config-section-header">Dodatkowe opłaty</Typography>
      <Paper className="config-section" elevation={3}>
        <div>
          <Field
            name={`${fieldPrefix}penalties.bikeLeftOutOfPreferredZone.enabled`}
            component={FormCheckBox}
            readonly={readonly}
            label="Dodatkowa opłata za pozostawienie roweru poza preferowaną strefą zwrotu"
            onChange={() => change(`${fieldPrefix}penalties.bikeLeftOutOfPreferredZone.amount`, '')}
            parse={Boolean}
          />
        </div>
        <div>
          <Field
            name={`${fieldPrefix}penalties.bikeLeftOutOfPreferredZone.amount`}
            type="number"
            component={FormTextField}
            readonly={readonly}
            label="Kwota opłaty"
            disabled={!bikeLeftOutOfPreferredZoneEnabled}
            parse={Number}
            endAdornmentText="zł"
          />
        </div>
        <div>
          <Field
            name={`${fieldPrefix}penalties.bikeLeftOutOfOperatorZone.enabled`}
            component={FormCheckBox}
            readonly={readonly}
            label="Kara za pozostawienie roweru poza obszarem operatora"
            onChange={() => change(`${fieldPrefix}penalties.bikeLeftOutOfOperatorZone.amount`, '')}
            parse={Boolean}
          />
          <Typography display="block" variant="caption">
            Kara zostanie doliczona gdy rower zostanie zamknięty poza obszarem operatora
          </Typography>
        </div>
        <div>
          <Field
            name={`${fieldPrefix}penalties.bikeLeftOutOfOperatorZone.amount`}
            type="number"
            component={FormTextField}
            readonly={readonly}
            label="Kwota kary"
            disabled={!bikeLeftOutOfOperatorZoneEnabled}
            parse={Number}
            endAdornmentText="zł"
          />
        </div>
      </Paper>

      {!isCard && cards.length > 1
        ? <React.Fragment>
          <Typography variant="subtitle1" gutterBottom className="config-section-header">Priorytet kart miejskich</Typography>
          <Paper className="config-section" elevation={3}>
            <Field
              name={`${fieldPrefix}cardsPriority`}
              component={FormDraggableField}
              readonly={readonly}
              values={cards.map(card => ({ id: card.id, title: card.publicName.pl, subtitle: `Dostawca ${card.cardProviderId}` }))}
            />
          </Paper>
        </React.Fragment>
        : null}

      <div className="actions">
        {!readonly && <Button variant="contained" color="primary" type="submit" disabled={submitting}>
          {submitting ? 'Zapisywanie...' : 'Zapisz'}
        </Button>}
      </div>
    </form>
  );
};

PricingDetailsForm.defaultProps = {
  fieldPrefix: '',
  isCard: false,
};

PricingDetailsForm.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  handleSubmit: PropTypes.func.isRequired,
  readonly: PropTypes.bool.isRequired,
  change: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  initialValues: PropTypes.object.isRequired,
  bikeTypes: PropTypes.array.isRequired,
  minimalBalanceEnabled: PropTypes.bool,
  initialBalanceEnabled: PropTypes.bool,
  bikeLeftInZoneEnabled: PropTypes.bool,
  bikeLeftOutOfPreferredZoneEnabled: PropTypes.bool,
  bikeLeftOutOfOperatorZoneEnabled: PropTypes.bool,
  fieldPrefix: PropTypes.string,
  cards: PropTypes.array.isRequired,
  isPlan: PropTypes.bool.isRequired,
  isCard: PropTypes.bool
};

PricingDetailsForm = reduxForm({
  form: 'pricing',
  validate: pricingFormValidation,
  enableReinitialize: true
})(PricingDetailsForm);

const getAvailableBikeTypes = (state, pricing) => {
  if (pricing.id != null) {
    if (pricing.bikeTypeId == null) return [{ id: null, name: 'Wszystkie' }]
    else return [state.bikeTypes.list.find(bt => bt.id === pricing.bikeTypeId)];
  } else {
    const types = [{ id: null, name: 'Wszystkie' }, ...state.bikeTypes.list];
    return types.filter(bt => !state.pricing.list.find(p => p.bikeTypeId == bt.id));
  }
};

const selector = formValueSelector('pricing');
PricingDetailsForm = connect(
  (state, ownProps) => ({
    initialBalanceEnabled: selector(state, `${ownProps.fieldPrefix}rentalPreconditions.initialBalance.enabled`),
    minimalBalanceEnabled: selector(state, `${ownProps.fieldPrefix}rentalPreconditions.minimalBalance.enabled`),
    bikeLeftInZoneEnabled: selector(state, `${ownProps.fieldPrefix}deductions.bikeLeftInZone.enabled`),
    bikeLeftOutOfPreferredZoneEnabled: selector(state, `${ownProps.fieldPrefix}penalties.bikeLeftOutOfPreferredZone.enabled`),
    bikeLeftOutOfOperatorZoneEnabled: selector(state, `${ownProps.fieldPrefix}penalties.bikeLeftOutOfOperatorZone.enabled`),
    initialValues: ownProps.initialValues || state.pricing.details,
    cards: state.cards.data ? state.cards.data.configured : [],
    bikeTypes: getAvailableBikeTypes(state, ownProps.initialValues || state.pricing.details)
  })
)(PricingDetailsForm);

export default PricingDetailsForm;
