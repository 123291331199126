import moment from 'moment';
import reportsApi from '../api/reportsApi';
import relocationConfigurationApi from '../api/relocationConfigurationApi';
import { actions as notificationActions } from '../actions/notificationActions';
import routePaths from '../routePaths';
import { push } from 'connected-react-router';

function storeBlobAs(blob, filename) {
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  setTimeout(() => URL.revokeObjectURL(url), 60000);
  if (typeof link.download === 'string') {
    document.body.appendChild(link);
    link.download = filename;
    link.href = url;
    link.click();
    document.body.removeChild(link);
  } else {
    location.replace(url);
  }
}

export const types = {
  GET_RELOCATION_DATA: 'GET_RELOCATION_DATA',
  GET_RELOCATION_DATA_SUCCESS: 'GET_RELOCATION_DATA_SUCCESS',
  EXPORT_RELOCATION_REPORT: 'EXPORT_RELOCATION_REPORT',
  EXPORT_RELOCATION_REPORT_SUCCESS: 'EXPORT_RELOCATION_REPORT_SUCCESS',
  EXPORT_HISTORY_RELOCATION_REPORT: 'EXPORT_HISTORY_RELOCATION_REPORT',
  EXPORT_HISTORY_RELOCATION_REPORT_SUCCESS: 'EXPORT_HISTORY_RELOCATION_REPORT_SUCCESS',
  EXPORT_HISTORY_RELOCATION_REPORT_FAILURE: 'EXPORT_HISTORY_RELOCATION_REPORT_FAILURE',
  GET_RELOCATION_CONFIGURATION: 'GET_RELOCATION_CONFIGURATION',
  GET_RELOCATION_CONFIGURATION_SUCCESS: 'GET_RELOCATION_CONFIGURATION_SUCCESS',
  GET_RELOCATION_CONFIGURATION_FAILURE: 'GET_RELOCATION_CONFIGURATION_FAILURE',
  UPDATE_RELOCATION_CONFIGURATION: 'UPDATE_RELOCATION_CONFIGURATION',
  UPDATE_RELOCATION_CONFIGURATION_SUCCESS: 'UPDATE_RELOCATION_CONFIGURATION_SUCCESS',
  UPDATE_RELOCATION_CONFIGURATION_FAILURE: 'UPDATE_RELOCATION_CONFIGURATION_FAILURE',
  EXPORT_GPX_REPORT: 'EXPORT_GPX_REPORT',
  EXPORT_GPX_REPORT_SUCCESS: 'EXPORT_GPX_REPORT_SUCCESS',
};

export const actions = {
  load: () => dispatch => {
    dispatch({ type: types.GET_RELOCATION_DATA });
    return reportsApi.getRelocationData().then(response => {
      if (response.ok) {
        dispatch({ type: types.GET_RELOCATION_DATA_SUCCESS, data: response.data });
      }
    });
  },
  requestExcelExport: () => dispatch => {
    dispatch({ type: types.EXPORT_RELOCATION_REPORT });
    return reportsApi.exportRelocationData().then(res => {
      if (res.ok && res.data.success !== false) {
        dispatch({ type: types.EXPORT_RELOCATION_REPORT_SUCCESS });
        storeBlobAs(res.data, `Relokacja_${moment().toISOString()}.xlsx`);
      } else {
        dispatch(notificationActions.showError('Eksport nie powiódł się'));
      }
    });
  },
  requestExcelHistoryExport: (data) => dispatch => {
    dispatch({ type: types.EXPORT_HISTORY_RELOCATION_REPORT });
    return reportsApi.exportRelocationHistoryData(data).then(res => {
      if (res.ok && res.data.success !== false) {
        dispatch({ type: types.EXPORT_HISTORY_RELOCATION_REPORT_SUCCESS });
        storeBlobAs(res.data, `Relokacja-Historias_${moment().toISOString()}.xlsx`);
      } else {
        dispatch({ type: types.EXPORT_HISTORY_RELOCATION_REPORT_FAILURE });
        dispatch(notificationActions.showError('Brak danych dla tego okresu, wybierz późniejszą datę.'));
      }
    });
  },
  loadConfiguration: () => dispatch => {
    dispatch({ type: types.GET_RELOCATION_CONFIGURATION });
    return relocationConfigurationApi.getDesiredBikeCounts().then(response => {
      if (response.ok) {
        dispatch({ type: types.GET_RELOCATION_CONFIGURATION_SUCCESS, data: response.data });
      } else {
        dispatch(notificationActions.showError('Nie udało się pobrać konfiguracji'));
        dispatch({ type: types.GET_RELOCATION_CONFIGURATION_FAILURE, data: response.data });
      }
    });
  },
  updateConfiguration: zoneLevels => dispatch => {
    dispatch({ type: types.UPDATE_RELOCATION_CONFIGURATION, zoneLevels });
    return relocationConfigurationApi.updateDesiredBikeCounts(zoneLevels).then(response => {
      if (response.ok) {
        dispatch({ type: types.UPDATE_RELOCATION_CONFIGURATION_SUCCESS, zoneLevels });
        dispatch(notificationActions.showSuccess('Zmiany zostały wprowadzone'));
        dispatch(push(routePaths.relocationReport));
      } else {
        dispatch(notificationActions.showError('Wystąpił problem podczas aktualizacji konfiguracji'));
        dispatch({ type: types.UPDATE_RELOCATION_CONFIGURATION_FAILURE, zoneLevels });
      }
    });
  },

  requestGpxExport: (rideId) => dispatch => {
    dispatch({ type: types.EXPORT_GPX_REPORT });
    return reportsApi.exportGpxReport(rideId).then(res => {
      if (res.ok && res.data.success !== false) {
        dispatch({ type: types.EXPORT_GPX_REPORT_SUCCESS });
        storeBlobAs(res.data, `Gpx-Export-${moment().toISOString()}.gpx`);
      } else {
        dispatch(notificationActions.showError('Eksport nie powiódł się'));
      }
    });
  },

};
