
export const ReportTypeForDialog = {
  seasonReport: { value: 'seasonReport', name: 'Sezonowy' },
  monthlyReport: { value: 'monthlyReport', name: 'Okresowy' },
  dayByDayAccountingReport: { value: 'dayByDayAccountingReport', name: 'Księgowy dobowy' },
  rentalByRentalAccountingReport: { value: 'rentalByRentalAccountingReport', name: 'Księgowy szczegółowy' },
  monthlyAccountingReport: { value: 'monthlyAccountingReport', name: 'Księgowy miesięczny' },
  userFundsAvailableToWithdraw: { value: 'userFundsAvailableToWithdraw', name: 'Depozyty userów dostępne do wypłacenia' },
  specialDepositWithdrawalReport: { value: 'specialDepositWithdrawalReport', name: 'Wypłaty depozytów'},
  userBikeLeftInZoneBonusReport: { value: 'userBikeLeftInZoneBonusReport', name: 'Bonusy użytkowników uzyskane za zwrócenie roweru'},
  complaintsReport: { value: 'complaintsReport', name: 'Reklamacje'},
  ridersReport: { value: 'ridersReport', name: 'Raport przejazdów'},

};


export const ReportTypeForOperatorRooveeDialog = {
  debtCollectionReport: { value: 'debtCollectionReport', name: 'Raport windykacyjny'},
  totalDistanceByUserReport: { value: 'totalDistanceByUserReport', name: 'Lista przejechanego dystansu przez użytkowników'},
};


export const ReportTypeClientUserRoleForDialog = {
  seasonReport: { value: 'seasonReport', name: 'Sezonowy' },
  monthlyReport: { value: 'monthlyReport', name: 'Okresowy' },
  dayByDayAccountingReport: { value: 'dayByDayAccountingReport', name: 'Księgowy dobowy' },
  rentalByRentalAccountingReport: { value: 'rentalByRentalAccountingReport', name: 'Księgowy szczegółowy' },
  monthlyAccountingReport: { value: 'monthlyAccountingReport', name: 'Księgowy miesięczny' },
};

if (window.location.hostname.startsWith('amazon.')) {
  ReportTypeForDialog.specialReport1 = { value: 'specialReport1', name: 'Dedykowany' }
  ReportTypeClientUserRoleForDialog.specialReport1 = { value: 'specialReport1', name: 'Dedykowany' };
}
if (window.location.hostname.startsWith('dev.') || window.location.hostname.startsWith('roovee.')) {
  ReportTypeForDialog.specialGlobalReport = { value: 'specialGlobalReport', name: 'Globalny (roczny)' };
  ReportTypeForDialog.specialMonthlyAccountingReport = { value: 'specialMonthlyAccountingReport', name: 'Raport zbiorczy' };
  ReportTypeForDialog.specialGlobalReportUserDebit = { value: 'specialGlobalReportUserDebit', name: 'Debety userów' };
  ReportTypeForDialog.globalUserAvailableFundsDepositReport = { value: 'globalUserAvailableFundsDepositReport', name: 'Globalne Depozyty dostępne do wypłacenia'};
}
